/* eslint-disable react-hooks/exhaustive-deps */
import { LoaderSpinner } from "components/Loader/LoaderSpinner";
import L from "leaflet";
import "leaflet-control-geocoder";
import { COLLECTIONS } from "model/constants";
import { useFirebase } from "model/context/firebase.context";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  ListGroup,
  ListGroupItem,
  Modal,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
  UncontrolledDropdown,
} from "reactstrap";
import { CustomerModalAddress } from "./CustomerModalAddress";
import { CustomerModalPropertyInfo } from "./CustomerModalPropertyInfo";
import { CustomerModalZillowInfo } from "./CustomerModalZillowInfo";

const emptyAddress = {
  title: "",
  address: "",
  address2: "",
  city: "",
  state: "",
  zipCode: "",
};

export function CustomerModal({ isLead, open, onClose, selectedItem }) {
  const navigate = useNavigate();
  const confirmDeleteTimeout = useRef();
  const confirmPromotionTimeout = useRef();
  const [error, setError] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const [addresses, setAddresses] = useState([]);
  const [newAddresses, setNewAddresses] = useState(
    selectedItem?.addressesId?.length > 0 ? [] : [{ ...emptyAddress }]
  );
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmPromote, setConfirmPromote] = useState(false);
  const [enableConfirmDelete, setEnableConfirmDelete] = useState(false);
  const [enableConfirmPromote, setEnableConfirmPromote] = useState(false);

  const [notes, setNotes] = useState(selectedItem?.notes || []);
  const { db } = useFirebase();
  const [nestedTab, setNestedTab] = useState("1");

  const [dropdownOriginValue, setDropdownOriginValue] = useState(
    selectedItem?.origin || "Select Lead Origin"
  );
  const [dropdownSalespersonValue, setDropdownSalespersonValue] = useState(
    selectedItem?.salesperson || "Select Responsible Salesperson"
  );

  // Additional states
  const [isVisited, setIsVisited] = useState(false);
  const [isbidSent, setbidSent] = useState(false);
  const [isInfoConfirmed, setisInfoConfirmed] = useState(false);
  const [requestedDoNotContact, setrequestedDoNotContact] = useState(false);
  const [hasBasementFinished, sethasBasementFinished] = useState(false);

  const [visitDate, setVisitDate] = useState("");
  const [bidNumber, setBidNumber] = useState("");
  const [bidDate, setBidDate] = useState("");
  const [bidValue, setBidValue] = useState("");

  // New fields
  const [isRejected, setIsRejected] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [reconnectBy, setReconnectBy] = useState("");
  const [observations, setObservations] = useState("");
  // New state for Cold/Stopped Answering checkbox
  const [isColdStopped, setIsColdStopped] = useState(false);

  // Add this near the top of the component with other state variables
  const [hasMigrated, setHasMigrated] = useState(false);

  // Add this useEffect to fetch the migration status
  useEffect(() => {
    const fetchMigrationStatus = async () => {
      try {
        const settings = await db.get(COLLECTIONS.settings, "");
        setHasMigrated(settings?.val()?.migrated || false);
      } catch (error) {
        console.error("Error fetching migration status:", error);
        setHasMigrated(false);
      }
    };

    fetchMigrationStatus();
  }, [db]);

  const handleDropdownOriginSelect = (e, value) => {
    e.preventDefault();
    setDropdownOriginValue(value);
  };

  const handleDropdownSalespersonSelect = (e, value) => {
    e.preventDefault();
    setDropdownSalespersonValue(value);
  };

  const config = isLead
    ? {
        formId: "#new-lead-form",
        collection: COLLECTIONS.leads,
        typeLabel: "Lead",
      }
    : {
        formId: "#new-customer-form",
        collection: COLLECTIONS.customers,
        typeLabel: "Customer",
      };

  const validateElement = (element) => {
    const { required, value, type, name } = element;
    if (name === "note" && !!value?.trim()) {
      // This line ensures the "note" field doesn't stay in an error state
      return "add note or delete content";
    } else if (required && !value) {
      return "required";
    } else if (
      type === "email" &&
      !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(value)
    ) {
      return "invalid email";
    }
  };

  const onValidate = () => {
    const errors = {};
    const form = document.querySelector(config.formId);
    for (let element of form.elements) {
      const { type, name } = element;
      if (type === "button") continue;
      const error = validateElement(element);
      if (error) errors[name] = error;
    }
    if (newAddresses.length === 0 && addresses.length === 0) {
      errors.address = "At least one address is required";
    }
    if (newAddresses.length > 0) {
      for (let address of newAddresses) {
        if (address.address === "") errors.address = "Address is required";
        if (address.city === "") errors.city = "City is required";
        if (address.state === "") errors.state = "State is required";
        if (address.zipCode === "") errors.zipCode = "Zip Code is required";
      }
    }

    if (addresses.length > 0) {
      for (let address of addresses) {
        if (address.address === "") errors.address = "Address is required";
        if (address.city === "") errors.city = "City is required";
        if (address.state === "") errors.state = "State is required";
        if (address.zipCode === "") errors.zipCode = "Zip Code is required";
      }
    }

    if (errors.address) {
      setError(errors.address);
      setErrors(errors);
      return errors;
    }

    return errors;
  };

  const onSubmit = async () => {
    const errors = onValidate();
    const values = {
      notes,
      isVisited,
      isbidSent,
      isInfoConfirmed,
      requestedDoNotContact,
      origin: dropdownOriginValue,
      salesperson: dropdownSalespersonValue,
      // Additional new fields
      isRejected,
      rejectReason,
      reconnectBy,
      observations,
      isColdStopped, // include the new field in submitted values
    };

    const form = document.querySelector(config.formId);
    for (let element of form.elements) {
      const { value, checked, type, name } = element;
      if (type === "button" || name === "note") continue;
      values[name] =
        type === "checkbox"
          ? checked
          : typeof value === "string"
          ? value.trim()
          : value;
    }

    if (Object.keys(errors).length) {
      setErrors(errors);
      setError("There are errors in the form.");
      console.log(errors);
    } else {
      setLoading(true);
      try {
        // If visit checkbox is checked, save visit info
        if (isVisited) {
          const visitData = {
            visitDate,
            customerId: selectedItem?.id, // undefined for new
            salesperson: dropdownSalespersonValue,
          };
          await db.insert("visits", visitData);
        }

        // If bid was sent, save bid info
        if (isbidSent) {
          const bidData = {
            bidNumber,
            bidDate,
            bidValue,
            customerId: selectedItem?.id, // undefined for new
          };
          await db.insert("bids-sent", bidData);
        }

        // Check if we're dealing with migrated data
        const isMigrated = selectedItem?.addressesId?.length > 0 || newAddresses?.length > 0;
        
        if (isMigrated) {
          // Post-migration: Handle addresses with their property and Zillow info
          
          // Handle deleted addresses
          const deletedAddresses = selectedItem?.addressesId?.filter(
            (addressId) => !addresses.some((address) => address.id === addressId)
          );

          const deletedAddressesPromise = deletedAddresses?.map(
            async (addressId) => {
              await db.delete("addresses", addressId);
            }
          );

          // Handle new addresses - they already contain property and Zillow info
          const newAddressesPromise = newAddresses?.map(async (address) => {
            const newAddressId = await db.insert("addresses", address);
            return { ...address, id: newAddressId };
          });

          // Handle updated addresses - they already contain updated property and Zillow info
          const updateAddressesPromise = addresses?.map(async (address) => {
            await db.update("addresses", address.id, address);
          });

          let newAddressesResult = [];
          if (newAddressesPromise?.length > 0) {
            newAddressesResult = await Promise.all(newAddressesPromise);
          }
          if (updateAddressesPromise?.length > 0) {
            await Promise.all(updateAddressesPromise);
          }
          if (deletedAddressesPromise?.length > 0) {
            await Promise.all(deletedAddressesPromise);
          }

          // Update addressesId in the contact document
          const addressesId = [...newAddressesResult, ...addresses].map(
            (address) => address.id
          );
          values.addressesId = addressesId;
        } else {
          // Pre-migration: Property and Zillow info are stored in the contact document
          
          // For pre-migration, we need to ensure all form fields are captured
          // Most fields are already captured from the form elements loop above
          
          // Make sure hasBasementFinished is included (it's managed by state, not directly from form)
          values.hasBasementFinished = hasBasementFinished;
          
          // Ensure checkbox values are properly set
          values.hasBasement = form.querySelector('[name="hasBasement"]')?.checked || false;
          values.hasBasementEntrance = form.querySelector('[name="hasBasementEntrance"]')?.checked || false;
          values.privateNotes = form.querySelector('[name="privateNotes"]')?.checked || false;
          
          // For pre-migration, we don't set addressesId
          // The address fields (address, address2, city, state, zipCode, etc.) are already in values
          // from the form elements loop
          
          // If this is a new contact (not editing), ensure we don't have an addressesId field
          if (!selectedItem) {
            delete values.addressesId;
          }
        }

        if (selectedItem) {
          await db.update(config.collection, selectedItem.id, values);
          onClose();
        } else {
          const insertedKey = await db.insert(config.collection, values);
          if (insertedKey) {
            onClose();
          } else {
            throw new Error("Record not generated");
          }
        }
      } catch (e) {
        setError(
          `${config.typeLabel} was not saved due to an error: ${
            e?.message || e
          }`
        );
      } finally {
        setLoading(false);
      }
    }
  };

  const onDelete = async () => {
    setLoading(true);
    try {
      if (selectedItem) await db.delete(config.collection, selectedItem.id);
      onClose();
    } catch (e) {
      setError(
        `${config.typeLabel} was not deleted due to an error: ${
          e?.messasge || e
        }`
      );
    } finally {
      setLoading(false);
    }
  };

  // Place this at the top of your module (before any geocoder is created)
  if (!L.Util._originalGetJSON) {
    L.Util._originalGetJSON = L.Util.getJSON;
  }
  L.Util.getJSON = function (url, callback, context) {
    console.log("L.Util.getJSON called with URL:", url);
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        console.log("L.Util.getJSON fetched data:", data);
        callback.call(context, data);
      })
      .catch((err) => console.error("getJSON error:", err));
  };

  const onPromote = async () => {
    setLoading(true);
    try {
      const { id, ...values } = selectedItem;
      const insertedKey = await db.insert(COLLECTIONS.customers, values);
      if (insertedKey) {
        await onDelete();
        navigate(`/admin/contacts/customers?lead=${insertedKey}`, {
          replace: true,
        });
      } else {
        throw new Error("Record not generated");
      }
    } catch (e) {
      setError(
        `${config.typeLabel} was not saved due to an error: ${e?.messasge || e}`
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchAddresses = async () => {
    if (selectedItem) {
      const addressesPromise = selectedItem.addressesId?.map(
        async (addressId) => {
          const addressSnapshot = await db.get("addresses", addressId);
          const address = addressSnapshot?.val();
          if (address) {
            return { ...address, id: addressId };
          }
          return null;
        }
      );

      if (addressesPromise?.length > 0) {
        const addresses = await Promise.all(addressesPromise);
        const filteredAddresses = addresses.filter(
          (address) => address !== null
        );
        if (filteredAddresses?.length > 0) {
          return setAddresses(filteredAddresses);
        }
        return setNewAddresses([{ ...emptyAddress }]);
      }
    }
  };

  console.log({ addresses });

  useEffect(() => {
    if (!open) {
      setErrors({});
      setError("");
      setTab(0);
      setConfirmDelete(false);
      setConfirmPromote(false);
      setEnableConfirmDelete(false);
      setEnableConfirmPromote(false);
      setNotes([]);
      setAddresses([]);
      setNewAddresses([]);
      clearTimeout(confirmDeleteTimeout.current);
      clearTimeout(confirmPromotionTimeout.current);

      if (selectedItem) {
        setDropdownOriginValue(selectedItem.origin || "Select Lead Origin");
        setDropdownSalespersonValue(
          selectedItem.salesperson || "Select Responsible Salesperson"
        );
      }
    } else {
      setNotes(selectedItem?.notes || []);
    }
  }, [open]);

  useEffect(() => {
    if (selectedItem) {
      setDropdownOriginValue(selectedItem.origin || "Select Lead Origin");
      setDropdownSalespersonValue(
        selectedItem.salesperson || "Select Responsible Salesperson"
      );
      setBidNumber(selectedItem.bidNumber || "");
      setBidDate(selectedItem.bidDate || "");
      setBidValue(selectedItem.bidValue || "");
      setVisitDate(selectedItem.visitDate || "");
      setIsVisited(selectedItem.isVisited || false);
      setisInfoConfirmed(selectedItem.isInfoConfirmed || false);
      setbidSent(selectedItem.isbidSent || false);
      setrequestedDoNotContact(selectedItem.requestedDoNotContact || false);
      sethasBasementFinished(selectedItem.hasBasementFinished || false);

      // New fields
      setIsRejected(selectedItem.isRejected || false);
      setRejectReason(selectedItem.rejectReason || "");
      setReconnectBy(selectedItem.reconnectBy || "");
      setObservations(selectedItem.observations || "");
      setIsColdStopped(selectedItem.isColdStopped || false);
      setAddresses(selectedItem.addresses || []);
      setNewAddresses(
        selectedItem.addressesId?.length > 0 ? [] : [{ ...emptyAddress }]
      );
      fetchAddresses();
    } else {
      // Reset for new
      setDropdownOriginValue("Select Lead Origin");
      setDropdownSalespersonValue("Select Responsible Salesperson");
      setBidNumber("");
      setBidDate("");
      setBidValue("");
      setVisitDate("");
      setIsVisited(false);
      setisInfoConfirmed(false);
      setbidSent(false);
      setrequestedDoNotContact(false);
      sethasBasementFinished(false);

      // Reset new fields
      setIsRejected(false);
      setRejectReason("");
      setReconnectBy("");
      setObservations("");
      setIsColdStopped(false);
      setAddresses([]);
      setNewAddresses([]);
      setNotes([]);
    }
  }, [selectedItem]);

  useEffect(() => {
    setError("");
  }, [tab]);

  const onChangeAddress = async (address, isNew, index) => {
    if (isNew)
      return setNewAddresses((prev) => {
        const newAddresses = [...prev];
        newAddresses[index] = address;
        return newAddresses;
      });

    return setAddresses((prev) => {
      const newAddresses = [...prev];
      newAddresses[index] = address;
      return newAddresses;
    });
  };

  const onDeleteAddress = (index, isNew) => {
    if (isNew) {
      return setNewAddresses((prev) => {
        const newAddresses = [...prev];
        newAddresses.splice(index, 1);
        return newAddresses;
      });
    }

    return setAddresses((prev) => {
      const newAddresses = [...prev];
      newAddresses.splice(index, 1);
      return newAddresses;
    });
  };

  const renderNotes = notes
    .sort((a, b) => (new Date(a.createdAt) > new Date(b.createdAt) ? -1 : 1))
    .map((note) => (
      <ListGroupItem key={note.createdAt}>
        <div className="text-light text-xs float-right">
          {new Date(note.createdAt).toDateString()}
        </div>
        <div
          className="pt-4"
          dangerouslySetInnerHTML={{
            __html: note.value ? note.value.replace(/\r|\n/gi, "<br/>") : "",
          }}
        />
      </ListGroupItem>
    ));

  return (
    <Modal isOpen={open} className="modal-dialog-centered modal-white modal-lg" >
      <div className="p-3">
        <div
          className="modal-body"
          style={{
            minHeight: 450,
          }}
        >
          <form
            id={config.formId.replace("#", "")}
            autoComplete="off"
            className="h-100"
            onChange={(e) => {
              const error = validateElement(e.target);
              const name = e.target.name;
              if (name !== "note" || (name === "note" && !error))
                setErrors({ ...errors, [name]: error });
            }}
          >
            <div className="d-md-flex flex-row justify-content-between align-items-start mb-5">
              <h3 className="text-muted font-weight-bold">
                {tab === 0
                  ? "Basic Information"
                  : tab === 1
                  ? "Address"
                  : tab === 2
                  ? "Visit & Bid"
                  : "Notes"}
              </h3>
              {!!selectedItem && (
                <div className="d-flex flex-flow justify-content-between">
                  {(confirmDelete || confirmPromote) && (
                    <Button
                      size="sm"
                      color="light"
                      type="button"
                      onClick={() => {
                        setConfirmDelete(false);
                        setConfirmPromote(false);
                      }}
                    >
                      Cancel
                    </Button>
                  )}
                  {!confirmPromote && (
                    <Button
                      size="sm"
                      className="ml-4"
                      color="danger"
                      type="button"
                      disabled={confirmDelete && !enableConfirmDelete}
                      onClick={() => {
                        if (confirmDelete) {
                          return onDelete();
                        }
                        clearTimeout(confirmDeleteTimeout.current);
                        setEnableConfirmDelete(false);
                        confirmDeleteTimeout.current = setTimeout(() => {
                          setEnableConfirmDelete(true);
                        }, 2000);
                        setConfirmDelete(!confirmDelete);
                      }}
                    >
                      {confirmDelete
                        ? "Confirm Delete"
                        : `Delete ${config.typeLabel}`}
                      {confirmDelete && !enableConfirmDelete && (
                        <Spinner size="sm" className="ml-2" />
                      )}
                    </Button>
                  )}
                  {isLead && !confirmDelete && (
                    <Button
                      size="sm"
                      className="ml-4"
                      color="info"
                      type="button"
                      disabled={confirmPromote && !enableConfirmPromote}
                      onClick={() => {
                        if (confirmPromote) {
                          return onPromote();
                        }
                        clearTimeout(confirmPromotionTimeout.current);
                        setEnableConfirmPromote(false);
                        confirmPromotionTimeout.current = setTimeout(() => {
                          setEnableConfirmPromote(true);
                        }, 2000);
                        setConfirmPromote(!confirmDelete);
                      }}
                    >
                      {confirmPromote
                        ? "Confirm Promotion"
                        : "Promote to Customer"}
                      {confirmPromote && !enableConfirmPromote && (
                        <Spinner size="sm" className="ml-2" />
                      )}
                    </Button>
                  )}
                </div>
              )}
            </div>

            {/* TAB 0: Basic Information */}
            <Row className={tab !== 0 ? "d-none" : ""}>
              <Col md="6">
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-control-label font-weight-light">
                        Name
                        <sup className={!!errors?.name ? "text-danger" : ""}>
                          * {errors.name}
                        </sup>
                      </label>
                      <Input
                        required
                        name="name"
                        className={`form-control text-dark ${
                          errors?.name ? "is-invalid" : ""
                        }`}
                        placeholder="Name"
                        type="text"
                        defaultValue={selectedItem?.name}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-control-label font-weight-light">
                        Last Name
                        <sup
                          className={!!errors?.lastName ? "text-danger" : ""}
                        >
                          * {errors.lastName}
                        </sup>
                      </label>
                      <Input
                        required
                        name="lastName"
                        className={`form-control text-dark ${
                          errors?.lastName ? "is-invalid" : ""
                        }`}
                        placeholder="Last Name"
                        type="text"
                        defaultValue={selectedItem?.lastName}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <label className="form-control-label font-weight-light">
                    Email Address
                    <sup className={!!errors?.email ? "text-danger" : ""}>
                      * {errors.email}
                    </sup>
                  </label>
                  <Input
                    required
                    name="email"
                    className={`form-control text-dark ${
                      errors?.email ? "is-invalid" : ""
                    }`}
                    placeholder="Email Address"
                    type="email"
                    defaultValue={selectedItem?.email}
                  />
                </FormGroup>
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label font-weight-light">
                        Phone (Mobile)
                        <sup className={!!errors?.mobile ? "text-danger" : ""}>
                          * {errors.mobile}
                        </sup>
                      </label>
                      <Input
                        required
                        name="mobile"
                        className={`form-control text-dark ${
                          errors?.mobile ? "is-invalid" : ""
                        }`}
                        placeholder="Phone (Mobile)"
                        type="tel"
                        defaultValue={selectedItem?.mobile}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label font-weight-light">
                        Phone (Other)
                      </label>
                      <Input
                        name="phone"
                        className={`form-control text-dark ${
                          errors?.phone ? "is-invalid" : ""
                        }`}
                        placeholder="Phone (Other)"
                        type="tel"
                        defaultValue={selectedItem?.phone}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>

              <Col md="6">
                <FormGroup>
                  <label className="form-control-label font-weight-light">
                    Origin
                  </label>
                  <br />
                  <Input
                    hidden
                    name="origin"
                    className={`form-control text-dark ${
                      errors?.origin ? "is-invalid" : ""
                    }`}
                    placeholder="Origin"
                    value={dropdownOriginValue}
                    readOnly
                  />
                  <UncontrolledDropdown>
                    <DropdownToggle caret color="primary">
                      {dropdownOriginValue}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        href="#"
                        onClick={(e) =>
                          handleDropdownOriginSelect(e, "Joist Migration")
                        }
                      >
                        Joist Migration
                      </DropdownItem>
                      <DropdownItem
                        href="#"
                        onClick={(e) =>
                          handleDropdownOriginSelect(e, "Passive Called/texted")
                        }
                      >
                        Passive Called/texted
                      </DropdownItem>
                      <DropdownItem
                        href="#"
                        onClick={(e) =>
                          handleDropdownOriginSelect(
                            e,
                            "Contacted us on Website"
                          )
                        }
                      >
                        Contacted us on Website
                      </DropdownItem>
                      <DropdownItem
                        href="#"
                        onClick={(e) =>
                          handleDropdownOriginSelect(
                            e,
                            "Contacted us on Social Media"
                          )
                        }
                      >
                        Contacted us on Social Media
                      </DropdownItem>
                      <DropdownItem
                        href="#"
                        onClick={(e) =>
                          handleDropdownOriginSelect(
                            e,
                            "Reference from another client"
                          )
                        }
                      >
                        Reference from another client
                      </DropdownItem>
                      <DropdownItem
                        href="#"
                        onClick={(e) =>
                          handleDropdownOriginSelect(e, "LandRercords Scrap")
                        }
                      >
                        LandRercords Scrap
                      </DropdownItem>
                      <DropdownItem
                        href="#"
                        onClick={(e) => handleDropdownOriginSelect(e, "Other")}
                      >
                        Other
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label font-weight-light">
                    Salesperson
                  </label>
                  <br />
                  <Input
                    hidden
                    name="salesperson"
                    className={`form-control text-dark ${
                      errors?.salesperson ? "is-invalid" : ""
                    }`}
                    placeholder="Salesperson"
                    value={dropdownSalespersonValue}
                    readOnly
                  />
                  <UncontrolledDropdown>
                    <DropdownToggle caret color="primary">
                      {dropdownSalespersonValue}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        href="#"
                        onClick={(e) =>
                          handleDropdownSalespersonSelect(e, "Vinny Maia")
                        }
                      >
                        Vinny Maia
                      </DropdownItem>
                      <DropdownItem
                        href="#"
                        onClick={(e) =>
                          handleDropdownSalespersonSelect(e, "Lucas Conde")
                        }
                      >
                        Lucas Conde
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </FormGroup>
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <div className="d-flex align-items-center">
                        <small className="form-control-label font-weight-light mr-3">
                          Correct contact info?
                        </small>
                        <div>
                          <label className="custom-toggle custom-toggle">
                            <input
                              type="checkbox"
                              name="isInfoConfirmed"
                              checked={isInfoConfirmed}
                              onChange={(e) =>
                                setisInfoConfirmed(e.target.checked)
                              }
                            />
                            <span
                              className="custom-toggle-slider rounded-circle"
                              data-label-off="No"
                              data-label-on="Yes"
                            />
                          </label>
                        </div>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <div className="d-flex align-items-center">
                        <small className="form-control-label font-weight-light mr-3">
                          Do Not Contact?
                        </small>
                        <div>
                          <label className="custom-toggle custom-toggle">
                            <input
                              type="checkbox"
                              name="requestedDoNotContact"
                              checked={requestedDoNotContact}
                              onChange={(e) =>
                                setrequestedDoNotContact(e.target.checked)
                              }
                            />
                            <span
                              className="custom-toggle-slider rounded-circle"
                              data-label-off="No"
                              data-label-on="Yes"
                            />
                          </label>
                        </div>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>

            {/* TAB 1: Address */}
            <Row className={tab !== 1 ? "d-none h-75" : "h-75"}>
              <Nav tabs className="nav-fill flex-column flex-sm-row">
                <NavItem>
                  <NavLink
                    className={nestedTab === "1" ? "active" : ""}
                    onClick={() => setNestedTab("1")}
                  >
                    Address
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={nestedTab === "2" ? "active" : ""}
                    onClick={() => setNestedTab("2")}
                  >
                    Property Information
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={nestedTab === "3" ? "active" : ""}
                    onClick={() => setNestedTab("3")}
                  >
                    Zillow Info
                  </NavLink>
                </NavItem>
              </Nav>

              <Col md="12">
                <TabContent activeTab={nestedTab}>
                  <TabPane tabId="1">
                    <div className="d-flex mb-3 justify-content-between align-items-center">
                      <h3>Address</h3>
                      {/* Only show Add Address button if migration has happened */}
                      {(selectedItem?.addressesId?.length > 0 || hasMigrated) && (
                        <Button
                          color="primary"
                          onClick={() =>
                            setNewAddresses((prev) => {
                              const newAddresses = [...prev];
                              newAddresses.push({ ...emptyAddress });
                              return newAddresses;
                            })
                          }
                        >
                          Add Address
                        </Button>
                      )}
                    </div>
                    <Row className="mb-3" style={{ overflowX: 'auto', whiteSpace: 'nowrap', display: 'flex', flexWrap: 'nowrap' }}>
                      {newAddresses?.map((address, index) => (
                        <Col md="6" key={index} style={{ minWidth: '450px', display: 'inline-block', float: 'none' }}>
                          <CustomerModalAddress
                            isUnique={addresses.length === 0 && newAddresses.length <= 1}
                            errors={errors}
                            address={address}
                            isNew={true}
                            onChangeAddress={(address) => onChangeAddress(address, true, index)}
                            onDeleteAddress={() => onDeleteAddress(index, true)}
                          />
                        </Col>
                      ))}
                      {addresses.map((address, index) => (
                        <Col md="6" key={address.id} style={{ minWidth: '450px', display: 'inline-block', float: 'none' }}>
                          <CustomerModalAddress
                            index={index}
                            isUnique={addresses.length <= 1}
                            onChangeAddress={(address) => onChangeAddress(address, false, index)}
                            errors={errors}
                            address={address}
                            onDeleteAddress={() => onDeleteAddress(index, false)}
                          />
                        </Col>
                      ))}
                    </Row>
                  </TabPane>

                  <TabPane tabId="2">
                    <div className="d-flex mb-3 justify-content-between align-items-center">
                      <h3>Property Information</h3>
                    </div>
                    <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
                      {/* For pre-migration data */}
                      {(!selectedItem?.addressesId || selectedItem?.addressesId?.length === 0) && (
                        <CustomerModalPropertyInfo
                          errors={errors}
                          address={selectedItem}
                          onChangeAddress={(updatedData) => {
                            // Handle pre-migration data updates
                            // This will update the contact document directly
                            Object.keys(updatedData).forEach(key => {
                              const input = document.querySelector(`[name="${key}"]`);
                              if (input) {
                                if (input.type === 'checkbox') {
                                  input.checked = updatedData[key];
                                } else {
                                  input.value = updatedData[key] || '';
                                }
                              }
                            });
                          }}
                        />
                      )}
                      
                      {/* For post-migration data */}
                      {selectedItem?.addressesId?.length > 0 && (
                        <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', display: 'flex', flexWrap: 'nowrap' }}>
                          {newAddresses?.map((address, index) => (
                            <div key={`new-${index}`} style={{ minWidth: '100%', marginBottom: '20px', paddingRight: '15px' }}>
                              <CustomerModalPropertyInfo
                                errors={errors}
                                address={address}
                                onChangeAddress={(updatedData) => onChangeAddress({...address, ...updatedData}, true, index)}
                              />
                              <hr />
                            </div>
                          ))}
                          {addresses.map((address, index) => (
                            <div key={`existing-${address.id}`} style={{ minWidth: '100%', marginBottom: '20px', paddingRight: '15px' }}>
                              <CustomerModalPropertyInfo
                                errors={errors}
                                address={address}
                                onChangeAddress={(updatedData) => onChangeAddress({...address, ...updatedData}, false, index)}
                              />
                              <hr />
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </TabPane>

                  <TabPane tabId="3">
                    <div className="d-flex mb-3 justify-content-between align-items-center">
                      <h3>Zillow Info</h3>
                    </div>
                    <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
                      {/* For pre-migration data */}
                      {(!selectedItem?.addressesId || selectedItem?.addressesId?.length === 0) && (
                        <CustomerModalZillowInfo
                          errors={errors}
                          address={selectedItem}
                          onChangeAddress={(updatedData) => {
                            // Handle pre-migration data updates
                            // This will update the contact document directly
                            Object.keys(updatedData).forEach(key => {
                              const input = document.querySelector(`[name="${key}"]`);
                              if (input) {
                                if (input.type === 'checkbox') {
                                  input.checked = updatedData[key];
                                } else {
                                  input.value = updatedData[key] || '';
                                }
                              }
                            });
                          }}
                        />
                      )}
                      
                      {/* For post-migration data */}
                      {selectedItem?.addressesId?.length > 0 && (
                        <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', display: 'flex', flexWrap: 'nowrap' }}>
                          {newAddresses?.map((address, index) => (
                            <div key={`new-${index}`} style={{ minWidth: '100%', marginBottom: '20px', paddingRight: '15px' }}>
                              <CustomerModalZillowInfo
                                errors={errors}
                                address={address}
                                onChangeAddress={(updatedData) => onChangeAddress({...address, ...updatedData}, true, index)}
                              />
                              <hr />
                            </div>
                          ))}
                          {addresses.map((address, index) => (
                            <div key={`existing-${address.id}`} style={{ minWidth: '100%', marginBottom: '20px', paddingRight: '15px' }}>
                              <CustomerModalZillowInfo
                                errors={errors}
                                address={address}
                                onChangeAddress={(updatedData) => onChangeAddress({...address, ...updatedData}, false, index)}
                              />
                              <hr />
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>

            {/* TAB 2: Visit & Bid ( + Rejection / Observations ) */}
            <Row className={tab !== 2 ? "d-none h-75" : "h-75"}>
              <Col md="12" className="h-100">
                <FormGroup className="h-100">
                  <label className="form-control-label font-weight-light">
                    Visit and Bid
                  </label>

                  {/* Visited Checkbox and Date of Visit */}
                  <div className="d-flex align-items-center mb-3">
                    <small className="form-control-label font-weight-light mr-3">
                      Visited?
                    </small>
                    <label className="custom-toggle custom-toggle">
                      <input
                        type="checkbox"
                        name="isVisited"
                        checked={isVisited}
                        onChange={(e) => setIsVisited(e.target.checked)}
                      />
                      <span
                        className="custom-toggle-slider rounded-circle"
                        data-label-off="No"
                        data-label-on="Yes"
                      />
                    </label>
                    <Input
                      name="visitDate"
                      type="date"
                      className="ml-3"
                      placeholder="Date of Visit"
                      value={visitDate}
                      onChange={(e) => setVisitDate(e.target.value)}
                    />
                  </div>

                  {/* Bid Sent Checkbox and Bid Information */}
                  <div className="d-flex align-items-center mb-3">
                    <small className="form-control-label font-weight-light mr-3">
                      Bid Sent?
                    </small>
                    <label
                      className="custom-toggle custom-toggle"
                      style={{ marginRight: "40px" }}
                    >
                      <input
                        type="checkbox"
                        name="isbidSent"
                        checked={isbidSent}
                        onChange={(e) => setbidSent(e.target.checked)}
                      />
                      <span
                        className="custom-toggle-slider rounded-circle"
                        data-label-off="No"
                        data-label-on="Yes"
                      />
                    </label>

                    <Input
                      name="bidNumber"
                      type="number"
                      className="ml-3"
                      placeholder="Bid Number"
                      value={bidNumber}
                      onChange={(e) => setBidNumber(e.target.value)}
                    />

                    <Input
                      name="bidDate"
                      type="date"
                      className="ml-3"
                      placeholder="Date Bid Sent"
                      value={bidDate}
                      onChange={(e) => setBidDate(e.target.value)}
                    />

                    <Input
                      name="bidValue"
                      type="number"
                      className="ml-3"
                      placeholder="Bid Value"
                      value={bidValue}
                      onChange={(e) => setBidValue(e.target.value)}
                    />
                  </div>

                  {/* Rejected Checkbox + Reason (only visible if "Rejected?" is true) */}
                  <div className="d-flex align-items-center mb-3">
                    <small className="form-control-label font-weight-light mr-3">
                      Rejected?
                    </small>
                    <label className="custom-toggle custom-toggle">
                      <input
                        type="checkbox"
                        name="isRejected"
                        checked={isRejected}
                        onChange={(e) => setIsRejected(e.target.checked)}
                      />
                      <span
                        className="custom-toggle-slider rounded-circle"
                        data-label-off="No"
                        data-label-on="Yes"
                      />
                    </label>

                    {isRejected && (
                      <FormGroup className="d-flex align-items-center">
                        <label className="form-control-label font-weight-light">
                          Reason:
                        </label>
                        <Input
                          type="select"
                          name="rejectReason"
                          className="form-control text-dark"
                          value={rejectReason}
                          onChange={(e) => setRejectReason(e.target.value)}
                        >
                          <option value="">-- Select Reason --</option>
                          <option>Chose someone else - price</option>
                          <option>Chose someone else - Timeline</option>
                          <option>Chose someone else - Referal</option>
                          <option>Chose someone else - Something Else</option>
                          <option>Didn't Clarify</option>
                          <option>Other</option>
                          <option>Decided to do it themselves</option>
                          <option>Paused</option>
                        </Input>
                      </FormGroup>
                    )}
                  </div>

                  {/* New Checkbox: Cold/Stopped Answering */}
                  <FormGroup className="d-flex align-items-center mb-3">
                    <small className="form-control-label font-weight-light mr-3">
                      Cold/Stopped Answering?
                    </small>
                    <label className="custom-toggle custom-toggle">
                      <input
                        type="checkbox"
                        name="isColdStopped"
                        checked={isColdStopped}
                        onChange={(e) => setIsColdStopped(e.target.checked)}
                      />
                      <span
                        className="custom-toggle-slider rounded-circle"
                        data-label-off="No"
                        data-label-on="Yes"
                      />
                    </label>
                  </FormGroup>

                  {/* Re-connect By and Observations (ALWAYS shown) */}
                  <FormGroup>
                    <label className="form-control-label font-weight-light">
                      Re-connect by
                    </label>
                    <Input
                      type="date"
                      name="reconnectBy"
                      className="form-control text-dark"
                      value={reconnectBy}
                      onChange={(e) => setReconnectBy(e.target.value)}
                    />
                  </FormGroup>

                  <FormGroup>
                    <label className="form-control-label font-weight-light">
                      Observations
                    </label>
                    <Input
                      type="textarea"
                      name="observations"
                      className="form-control text-dark"
                      placeholder="Add any observations here..."
                      value={observations}
                      onChange={(e) => setObservations(e.target.value)}
                    />
                  </FormGroup>
                </FormGroup>
              </Col>
            </Row>

            {/* TAB 3: Notes */}
            <Row className={tab !== 3 ? "d-none h-75" : "h-75"}>
              <Col md="6" className="h-100">
                <FormGroup className="h-100">
                  <div className="d-flex flex-row justify-content-between">
                    <label className="form-control-label font-weight-light">
                      New Note{" "}
                      <sup className={!!errors?.note ? "text-danger" : ""}>
                        {errors.note}
                      </sup>
                    </label>
                    <Button
                      size="sm"
                      color="link"
                      type="button"
                      onClick={() => {
                        const form = document.querySelector(config.formId);
                        const value = form.elements["note"].value;
                        if (value) {
                          form.elements["note"].value = "";
                          setNotes([
                            ...notes,
                            {
                              createdAt: new Date().toISOString(),
                              value,
                            },
                          ]);
                        }
                      }}
                    >
                      Add Note
                    </Button>
                  </div>
                  <Input
                    name="note"
                    className={`form-control text-dark h-100 ${
                      errors?.note ? "is-invalid" : ""
                    }`}
                    placeholder="Note won't be added until the 'Add Note' button is clicked."
                    type="textarea"
                    style={{
                      minHeight: 270,
                      resize: "none",
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <ListGroup flush className="pre-scrollable d-none d-md-block">
                  {renderNotes}
                </ListGroup>
                <ListGroup flush className="d-md-none d-block">
                  {renderNotes}
                </ListGroup>
              </Col>
            </Row>
          </form>
          {!!error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
        </div>
        <div className="modal-footer">
          <Button color="link" type="button" onClick={onClose}>
            Cancel
          </Button>
          <Button
            className="ml-auto"
            color="dark"
            type="button"
            onClick={() => setTab(tab - 1 <= 0 ? 0 : tab - 1)}
          >
            <i className="ni ni-bold-left" />
          </Button>
          {tab < 3 ? (
            <Button
              className="ml-1"
              color="dark"
              type="button"
              onClick={() => setTab(tab + 1)}
            >
              <i className="ni ni-bold-right" />
            </Button>
          ) : (
            <Button
              className="ml-1"
              color={!!error ? "danger" : "info"}
              type="button"
              onClick={onSubmit}
            >
              <i className="ni ni-check-bold" />
            </Button>
          )}
        </div>
      </div>
      {loading && <LoaderSpinner />}
    </Modal>
  );
}
