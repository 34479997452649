import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { useFirebase } from "model/context/firebase.context";
import { COLLECTIONS } from "model/constants";
import { CustomerModal } from "components/Contacts/CustomerModal";

const LeadCard = ({ lead, onCardClick }) => {
  return (
    <div
      style={{ marginBottom: "10px", cursor: "pointer" }}
      onClick={() => onCardClick(lead)}
    >
      <Card className="pipeline-card" style={{ padding: "10px" }}>
        <CardBody>
          <div style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
            {lead.name} {lead.lastName}
          </div>
          <div style={{ fontSize: "0.9rem", color: "gray" }}>{lead.phone}</div>
          <div style={{ fontSize: "0.9rem", color: "gray" }}>
            {lead.address}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

const PipelineColumn = ({ title, leads, onCardClick }) => {
  return (
    <div style={{ minHeight: "300px", padding: "10px" }}>
      <Card className="shadow">
        <CardHeader>
          {title} ({leads.length})
        </CardHeader>
        <CardBody>
          {leads.map((lead) => (
            <LeadCard key={lead.id} lead={lead} onCardClick={onCardClick} />
          ))}
        </CardBody>
      </Card>
    </div>
  );
};

const SalesPipeline = () => {
  const { db } = useFirebase();

  // 1. Maintain all leads from Firestore
  const [allLeads, setAllLeads] = useState([]);

  // 2. Maintain pipeline columns in the desired order
  // Updated to include a new column for "Cold/Stopped Answering"
  const [columns, setColumns] = useState({
    1: { id: "1", title: "Information Confirmed", leads: [] },
    2: { id: "2", title: "Visited", leads: [] },
    3: { id: "3", title: "Bid Sent", leads: [] },
    4: { id: "4", title: "Reconnect", leads: [] },
    5: { id: "5", title: "Win", leads: [] },
    6: { id: "6", title: "Cold/Stopped Answering", leads: [] },
    7: { id: "7", title: "Rejected", leads: [] },
  });

  // 3. Track selected lead & modal state
  const [selectedLead, setSelectedLead] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // 4. Salesperson filter
  //    "any" => all leads
  //    "none" => leads where salesperson is null, undefined, or ""
  //    "Vinny Maia" => only leads with that salesperson
  //    "Lucas Conde" => only leads with that salesperson
  const [selectedSalesperson, setSelectedSalesperson] = useState("any");

  // Handler to open modal with correct lead
  const handleCardClick = (lead) => {
    setSelectedLead(lead);
    setIsModalOpen(true);
  };

  // 5. Fetch leads from Firestore
  useEffect(() => {
    const unsubscribe = db.listen(COLLECTIONS.leads, (data) => {
      if (data) {
        const leads = Object.entries(data).map(([key, value]) => ({
          id: key,
          ...value,
        }));
        setAllLeads(leads);
      } else {
        setAllLeads([]);
      }
    });
    return () => {
      unsubscribe();
    };
  }, [db]);

  // 6. Filter leads by selectedSalesperson, then distribute into columns
  useEffect(() => {
    // Filter by "any", "none", "Vinny Maia", "Lucas Conde"
    let filteredLeads = [...allLeads];
    if (selectedSalesperson === "none") {
      filteredLeads = filteredLeads.filter(
        (lead) =>
          !lead.salesperson ||
          lead.salesperson.trim() === "" ||
          lead.salesperson.trim() === "Select Responsible Salerperson"
      );
    } else if (selectedSalesperson !== "any") {
      // e.g., "Vinny Maia" or "Lucas Conde"
      filteredLeads = filteredLeads.filter(
        (lead) => lead.salesperson === selectedSalesperson
      );
    }

    // Distribute leads into columns
    const confirmedLeads = [];
    const visitedLeads = [];
    const bidSentLeads = [];
    const reconnectLeads = [];
    const winLeads = [];
    const coldStoppedLeads = [];
    const rejectedLeads = [];

    filteredLeads.forEach((lead) => {
      if (lead.isColdStopped) {
        // Leads with isColdStopped true go here
        coldStoppedLeads.push(lead);
      } else if (lead.isRejected) {
        rejectedLeads.push(lead);
      } else if (lead.isInfoConfirmed && !lead.isVisited) {
        confirmedLeads.push(lead);
      } else if (lead.isVisited && !lead.isbidSent) {
        visitedLeads.push(lead);
      } else if (lead.isbidSent) {
        bidSentLeads.push(lead);
      }
      // Add additional logic for reconnect/win if available
    });

    // Update columns with the new distribution
    setColumns((prev) => ({
      ...prev,
      1: { ...prev["1"], leads: confirmedLeads },
      2: { ...prev["2"], leads: visitedLeads },
      3: { ...prev["3"], leads: bidSentLeads },
      4: { ...prev["4"], leads: reconnectLeads },
      5: { ...prev["5"], leads: winLeads },
      6: { ...prev["6"], leads: coldStoppedLeads },
      7: { ...prev["7"], leads: rejectedLeads },
    }));
  }, [allLeads, selectedSalesperson]);

  return (
    <Container className="mt-4" fluid>
      {/* 7. Filter UI */}
      <Row className="mb-3">
        <Col md="3">
          <FormGroup>
            <Label for="salespersonFilter">Salesperson</Label>
            <Input
              id="salespersonFilter"
              type="select"
              value={selectedSalesperson}
              onChange={(e) => setSelectedSalesperson(e.target.value)}
            >
              <option value="any">Any</option>
              <option value="none">None</option>
              <option value="Vinny Maia">Vinny Maia</option>
              <option value="Lucas Conde">Lucas Conde</option>
            </Input>
          </FormGroup>
        </Col>
      </Row>

      {/* 8. Pipeline Columns */}
      <Row>
        {Object.values(columns).map((column) => (
          <Col key={column.id} md="2">
            <PipelineColumn
              title={column.title}
              leads={column.leads}
              onCardClick={handleCardClick}
            />
          </Col>
        ))}
      </Row>

      {/* 9. Lead-editing Modal */}
      <CustomerModal
        isLead
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        selectedItem={selectedLead}
      />
    </Container>
  );
};

export default SalesPipeline;
