/* eslint-disable react-hooks/exhaustive-deps */
import SimpleHeader from "components/Headers/SimpleHeader";
import { LoaderSpinner } from "components/Loader/LoaderSpinner";
import { ProjectCommentsTimeline } from "components/Project/ProjectCommentsTimeline";
import { ProjectFormCard } from "components/Project/ProjectFormCard";
import { ProjectImagesCard } from "components/Project/ProjectImagesCard";
import { ProjectTicketsCard } from "components/Project/ProjectTicketsCard";
import { ProjectTradesCard } from "components/Project/ProjectTradesCard";
import { ProjectTradesModal } from "components/Project/ProjectTradesModal";
import { ProjectTransactionsCard } from "components/Project/ProjectTransactionsCard";
import { COLLECTIONS } from "model/constants";
import { useFirebase } from "model/context/firebase.context";
import { customAlphabet as nanoid } from "nanoid";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Toast,
  ToastBody,
} from "reactstrap";
import { isImage, transformData } from "utils";

function ProjectView() {
  const errorTimeout = useRef();
  const successTimeout = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const { db, storage } = useFirebase();

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);

  const [id, setId] = useState(location.pathname.split("/").pop());
  // We no longer generate an internal ID here. We start with null.
  const internal = useRef(null);

  const [selectedItem, setSelectedItem] = useState(null);
  const [customer, setCustomer] = useState();
  const [customers, setCustomers] = useState([]);
  const [leads, setLeads] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [projectTrades, setProjectTrades] = useState([]);
  const [projectTradesTasks, setProjectTradesTasks] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [images, setImages] = useState([]);
  const [projectTasksImages, setProjectTasksImages] = useState([]);
  const [comments, setComments] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [transactions, setTransactions] = useState([]);

  const [addTradeModalOpen, setAddTradeModalOpen] = useState(false);
  const [selectedTradeTask, setSelectedTradeTask] = useState(null);
  const [selectedMaterials, setSelectedMaterials] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);

  // This is in your code but unused
  const [, setProjectId] = useState([]);

  // Validation helper
  const validateElement = (element) => {
    const { required, value, type, name } = element;
    if (name === "note" && !!value?.trim()) {
      return "add note or delete content";
    } else if (required && !value) {
      return "required";
    } else if (
      type === "email" &&
      !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(value)
    ) {
      return "invalid email";
    }
  };

  const updateCustomer = async (cust) => {
    await db.update(COLLECTIONS.customers, cust.id, cust);
    setCustomer(cust);
  };

  // Validate entire form
  const onValidate = () => {
    const formErrors = {};
    const form = document.querySelector("#project-form");
    if (!form) return formErrors;

    for (let element of form.elements) {
      const { type, name } = element;
      if (type === "button") continue;
      const err = validateElement(element);
      if (err && name) formErrors[name] = err;
    }
    return formErrors;
  };

  // Save/Update project
  const onSubmit = async () => {
    const formErrors = onValidate();
    if (Object.keys(formErrors).length) {
      setErrors(formErrors);
      setError("There are errors in the form.");
      return;
    }

    setLoading(true);
    try {
      // Prepare "values" for DB
      const values = {
        // If the DB already has an internalId, use it; otherwise use the ref
        internalId: selectedItem?.internalId || internal.current,
      };

      // Gather form data from the DOM
      const form = document.querySelector("#project-form");
      if (form) {
        for (let element of form.elements) {
          const { name, type, value, checked } = element;
          if (
            !name ||
            type === "button" ||
            name === "note" ||
            name.includes("supply-")
          ) {
            continue;
          }
          values[name] =
            type === "checkbox"
              ? checked
              : typeof value === "string"
                ? value.trim()
                : value;
        }
      }

      // Copy materials into values
      values.materials = selectedMaterials.map(
        ({ id, renderKey, name, quantity, price, tax, total }) => ({
          id,
          renderKey,
          name,
          quantity: quantity || 1,
          price,
          tax,
          total,
        })
      );

      // Check if existing project
      const recordExists = selectedItem?.id
        ? await db.exists(COLLECTIONS.projects, selectedItem.id)
        : false;

      if (recordExists) {
        // Update
        await db.update(COLLECTIONS.projects, selectedItem.id, values);
        setSelectedItem({ ...selectedItem, ...values });
      } else {
        // Insert new
        const insertedKey = await db.insert(COLLECTIONS.projects, values);
        if (!insertedKey) {
          console.error("insertedKey is undefined");
          throw new Error("Record not generated: insertedKey is undefined");
        }
        setSelectedItem({ id: insertedKey, ...values });
        navigate(`/admin/projects/${insertedKey}`, { replace: true });
        setId(insertedKey);
      }

      // If there's no existing customerId, create a new customer doc
      if (!values.customerId) {
        const newCustomer = {
          name: values.customer,
          privateNotes: false,
        };
        const insertedCustKey = await db.insert(
          COLLECTIONS.customers,
          newCustomer
        );
        setCustomer({ ...newCustomer, id: insertedCustKey });
      }

      setSuccess(true);
    } catch (e) {
      console.error("Error in onSubmit:", e);
      setError(`Project was not saved due to an error: ${e?.message || e}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // If brand new project
    if (id === "new") {
      internal.current = nanoid(
        "1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
      )();
      setSelectedItem(internal.current);
      setLoading(false);
    } else {
      let unsubscribeProject, unsubscribeComments, unsubscribeTransactions;

      // Listen to entire PROJECT doc, which also includes project.tickets
      unsubscribeProject = db.listen(COLLECTIONS.projects, (allProjects) => {
        const project = allProjects[id];
        if (!project) {
          console.warn("Project not found or deleted:", id);
          setLoading(false);
          return;
        }
        // Safely handle missing name
        if (!project.name) {
          console.warn("Project is missing a 'name' field:", project);
        }

        // handle internalId (unchanged from your code)
        if (project.internalId) {
          internal.current = project.internalId;
        } else {
          const newInternal = nanoid(
            "1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
          )();
          project.internalId = newInternal;
          db.update(COLLECTIONS.projects, id, project);
          internal.current = newInternal;
        }

        // set local project & materials
        setSelectedItem({ id, ...project });
        setSelectedMaterials(project.materials || []);

        // If the project has a `tickets` object, transform it to an array
        if (project.tickets) {
          const ticketsArray = Object.entries(project.tickets).map(
            ([ticketId, ticketData]) => ({
              id: ticketId,
              ...ticketData,
            })
          );
          // e.g., sort newest first
          ticketsArray.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setTickets(ticketsArray);
        } else {
          setTickets([]);
        }

        // existing code for images, transactions, comments, etc...
        storage
          .get(`${COLLECTIONS.projects}/${internal.current}`)
          .then((result) => {
            if (result.items) setImages(result.items);
          })
          .catch(console.error);

        // transactions listener
        unsubscribeTransactions = db.listen(
          `${COLLECTIONS.projects}/${id}/transactions`,
          (data) => {
            if (data) {
              setTransactions(
                transformData(data).sort((a, b) =>
                  new Date(a.createdAt) > new Date(b.createdAt) ? -1 : 1
                )
              );
            } else {
              setTransactions([]);
            }
          }
        );

        // comments listener
        unsubscribeComments = db.listen(
          `${COLLECTIONS.projectComments}/${internal.current}`,
          (data) => {
            if (data) {
              setComments(
                transformData(data).sort((a, b) =>
                  new Date(a.createdAt) > new Date(b.createdAt) ? -1 : 1
                )
              );
            } else {
              setComments([]);
            }
          }
        );

        setLoading(false);
      });

      // Cleanup
      return () => {
        if (unsubscribeProject) unsubscribeProject();
        if (unsubscribeComments) unsubscribeComments();
        if (unsubscribeTransactions) unsubscribeTransactions();
      };
    }
  }, [id]);

  // Listen to customers, leads, etc.
  useEffect(() => {
    const unsubscribeCustomers = db.listen(COLLECTIONS.customers, (data) => {
      setCustomers(transformData(data));
    });
    const unsubscribeLeads = db.listen(COLLECTIONS.leads, (data) => {
      setLeads(transformData(data));
    });
    const unsubscribeMaterials = db.listen(COLLECTIONS.materials, (data) => {
      setMaterials(transformData(data));
    });
    const unsubscribeSuppliers = db.listen(COLLECTIONS.suppliers, (data) => {
      setSuppliers(transformData(data));
    });
    const unsubscribeTrades = db.listen(COLLECTIONS.trades, (data) => {
      setProjectTrades(data || []);
    });

    return () => {
      unsubscribeCustomers();
      unsubscribeLeads();
      unsubscribeMaterials();
      unsubscribeSuppliers();
      unsubscribeTrades();
    };
  }, []);

  const fetchProjectTasksImages = async (projectTasks = []) => {
    if (projectTasks.length === 0) {
      setProjectTasksImages([]);
      return;
    }

    const projectTasksImagesPromises = projectTasks.map(async (task) => {
      const queryString = `${COLLECTIONS.projects}/${internal.current}/${task.id}`;
      const imagesRef = await storage.getFiles(queryString);
      return imagesRef || [];
    });

    const projectTasksImages = await Promise.all(projectTasksImagesPromises);
    const flattenedImages = projectTasksImages
      .flatMap((taskImg) => taskImg)
      .filter((taskImg) => isImage(taskImg.name));

    setProjectTasksImages(flattenedImages);
  };

  const refreshProjectTasksImages = useCallback(async () => {
    await fetchProjectTasksImages(projectTradesTasks);
  }, [projectTradesTasks]);

  // Listen for projectTrades tasks if we have selectedItem
  useEffect(() => {
    let unsubscribeTradeTask;
    if (selectedItem && selectedItem.tasks) {
      unsubscribeTradeTask = db.listen(
        COLLECTIONS.projectTrades,
        (allTrades) => {
          if (!allTrades) {
            setProjectTradesTasks([]);
            setProjectTasksImages([]);
            return;
          }
          const transformedData = transformData(allTrades);
          const projectTasks = selectedItem.tasks || [];

          const filteredTasks = transformedData.filter((task) =>
            projectTasks.includes(task.id)
          );
          fetchProjectTasksImages(filteredTasks);
          setProjectTradesTasks(filteredTasks);
        }
      );
    }
    return () => {
      if (unsubscribeTradeTask) unsubscribeTradeTask();
    };
  }, [selectedItem]);

  // Toast cleanup for error
  useEffect(() => {
    clearTimeout(errorTimeout.current);
    if (error) {
      errorTimeout.current = setTimeout(() => {
        setError(null);
      }, 5000);
    }
    return () => {
      clearTimeout(errorTimeout.current);
    };
  }, [error]);

  // Toast cleanup for success
  useEffect(() => {
    clearTimeout(successTimeout.current);
    if (success) {
      successTimeout.current = setTimeout(() => {
        setSuccess(false);
      }, 5000);
    }
    return () => {
      clearTimeout(successTimeout.current);
    };
  }, [success]);

  // Keep track of project ID in local state (unused but in your code)
  useEffect(() => {
    if (selectedItem?.id) {
      setProjectId(selectedItem.id);
    }
  }, [selectedItem]);

  // Delete a trade from this project
  const onDeleteTrade = (tradeId) => {
    if (!selectedItem?.id) return;
    const copy = { ...selectedItem };
    const tasks = copy.tasks || [];
    copy.tasks = tasks.filter((t) => t !== tradeId);
    setSelectedItem(copy);
    db.update(COLLECTIONS.projects, copy.id, copy);
  };

  // Delete an image from the project
  const onDeleteFilterImage = (image) => {
    const isProjectTaskImage = projectTasksImages.some(
      (img) => img.fullPath === image.fullPath
    );

    if (isProjectTaskImage) {
      return setProjectTasksImages((prev) => {
        const filteredImages = prev.filter(
          (img) => img.fullPath !== image.fullPath
        );
        return filteredImages;
      });
    }
    setImages((prev) => {
      const filteredImages = prev.filter(
        (img) => img.fullPath !== image.fullPath
      );
      return filteredImages;
    });
  };

  const allImages = useMemo(() => {
    return [...images, ...projectTasksImages];
  }, [images, projectTasksImages]);

  return (
    <>
      <SimpleHeader
        actions={[
          ...(selectedItem?.id
            ? [
              {
                label: "Delete Project",
                color: "danger",
                handler: () => setConfirmDelete(true),
              },
            ]
            : []),
          {
            label: `${!selectedItem?.id ? "Save" : "Update"} Project`,
            handler: onSubmit,
          },
          {
            icon: `fas fa-print`,
            handler: () => window.print(),
          },
        ]}
        current={{ name: loading ? "" : selectedItem?.name || "New" }}
        parent={{ name: "Projects", route: "/projects" }}
      />

      {!loading ? (
        <form
          id="project-form"
          autoComplete="off"
          onChange={(e) => {
            const fieldErr = validateElement(e.target);
            if (
              e.target.name !== "note" ||
              (e.target.name === "note" && !fieldErr)
            ) {
              setErrors({ ...errors, [e.target.name]: fieldErr });
            }
          }}
        >
          <Container className="mt--6 mb-6" fluid>
            <Row>
              <Col xs="12">
                <ProjectFormCard
                  selectedItem={selectedItem}
                  errors={errors}
                  customer={customer}
                  customers={customers}
                  leads={leads}
                  setCustomer={setCustomer}
                  updateCustomer={updateCustomer}
                />
              </Col>

              <Col xs="12" md="6">
                <ProjectTransactionsCard
                  projectId={selectedItem?.id}
                  transactions={transactions}
                  internal={internal}
                  comments={comments}
                  setError={setError}
                />
              </Col>

              <Col xs="12" md="6">
                <ProjectImagesCard
                  internal={internal}
                  images={allImages}
                  setImages={setImages}
                  setError={setError}
                  onDelete={onDeleteFilterImage}
                />
              </Col>

              <Col xs="12" md="6" style={{ marginTop: "20px" }}>
                <ProjectCommentsTimeline
                  internal={internal}
                  comments={comments}
                  setError={setError}
                />
              </Col>

              <Col xs="12" md="6" style={{ marginTop: "20px" }}>
                <ProjectTicketsCard
                  projectId={selectedItem?.id}
                  projectName={selectedItem?.name}
                  tickets={tickets}
                  setTickets={setTickets}
                  setError={setError}
                />
              </Col>

              <Col xs="12">
                <ProjectTradesCard
                  newTradesDisabled={id === "new"}
                  onDeleteTrade={onDeleteTrade}
                  projectTradesTasks={projectTradesTasks}
                  materials={materials}
                  errors={errors}
                  selectedItem={selectedItem}
                  setAddTradeModalOpen={setAddTradeModalOpen}
                  setError={setError}
                  selectedMaterials={selectedMaterials}
                  setSelectedMaterials={setSelectedMaterials}
                  setSelectedTradeTask={setSelectedTradeTask}
                />
              </Col>
            </Row>
          </Container>
        </form>
      ) : (
        <LoaderSpinner />
      )}

      {/* TRADES MODAL */}
      <ProjectTradesModal
        projectId={selectedItem?.id}
        open={addTradeModalOpen}
        onClose={() => setAddTradeModalOpen(false)}
        projectTrades={projectTrades}
        suppliers={suppliers}
        materials={materials}
        selectedTradeTask={selectedTradeTask}
        onDidCreate={async (values) => {
          if (!selectedItem?.id) return;
          const tasks = selectedItem.tasks || [];
          tasks.push(values.id);
          const updatedProject = { ...selectedItem, tasks };
          setSelectedItem(updatedProject);
          await db.update(
            COLLECTIONS.projects,
            selectedItem.id,
            updatedProject
          );
        }}
        tradeId={selectedTradeTask?.id}
        internal={internal}
        refreshImages={refreshProjectTasksImages}
      />

      {/* DELETE CONFIRMATION MODAL */}
      <Modal isOpen={confirmDelete} toggle={() => setConfirmDelete(false)}>
        <ModalHeader>Delete project</ModalHeader>
        <ModalBody>
          <p>Do you really want to delete the project?</p>
          <p>This action can't be reverted.</p>
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={() => setConfirmDelete(false)}>
            Cancel
          </Button>
          <Button
            className="ml-4"
            color="danger"
            onClick={async () => {
              if (!selectedItem?.id) return;
              await db.delete(COLLECTIONS.projects, selectedItem.id);

              // Also remove images from storage if any
              if (internal.current && images.length) {
                await Promise.all(
                  images.map((image) =>
                    storage.delete(
                      `${COLLECTIONS.projects}/${internal.current}`,
                      image.name
                    )
                  )
                ).catch(console.error);
              }
              navigate(`/admin/projects`, { replace: true });
            }}
          >
            Yes, delete
          </Button>
        </ModalFooter>
      </Modal>

      {/* ERROR TOAST */}
      {error && (
        <div className="position-absolute fixed-top p-3 bg-danger m-2 rounded">
          <Toast>
            <ToastBody className="text-white">{error}</ToastBody>
          </Toast>
        </div>
      )}

      {/* SUCCESS TOAST */}
      {success && (
        <div className="position-absolute fixed-top p-3 bg-success m-2 rounded">
          <Toast>
            <ToastBody className="text-white">Saved</ToastBody>
          </Toast>
        </div>
      )}
    </>
  );
}

export default ProjectView;
